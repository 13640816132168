<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">
        <div class="vx-col w-full md:w-3/3">
          <div class="vx-card p-6 mb-6">
            <CategoryForm></CategoryForm>
          
          </div>
        </div>
       </div>

            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <SettingInfo></SettingInfo>
 
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import CategoryForm from './common/CategoryForm';
import SettingInfo from './common/SettingInfo';
import loaderMixin from '../../../../mixins/loaderMixin';
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    vSelect,
    CategoryForm,
    SettingInfo
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'blog_category/getFormObj',
    })
  },
  created() {
      this.clearForm();
      this.clearValidationErrors();
    },
  methods: {
    ...mapActions({
      createCategory: 'blog_category/createCategory',
      clearForm: 'blog_category/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    submitForm() {
      this.openLoading();
      this.createCategory(this.form)
        .then(async response => {
           if(response.status === 201) {
            this.$vs.notify({ 
                title: 'Blog Category Created',
                text: 'Blog Category created successfully.',
                color: 'success',
                position: 'top-right'
            });
            this.closeLoading();
            this.$router.push('/categories');
           }
        }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
